import {createStore} from 'vuex'

export default createStore({
    state: {
        userName: localStorage.getItem('userName'),
        userId: sessionStorage.getItem('userId'),
        phoneNo: sessionStorage.getItem('phoneNo'),
        token: sessionStorage.getItem('token'),
        auth: sessionStorage.getItem('auth'),
        ossToken: localStorage.getItem('ossToken') ? JSON.parse(localStorage.getItem('ossToken')) : {},//待上传记录
    },
    getters: {},
    mutations: {
        setUserName(state, val) {
            state.userName = val
            localStorage.setItem('userName', val)
        },
        setToken(state, val) {
            state.token = val
            sessionStorage.setItem('token', val)
        },
        setPhoneNo(state, val) {
            state.phoneNo = val
            sessionStorage.setItem('phoneNo', val)
        },
        setUserId(state, val) {
            state.userId = val
            sessionStorage.setItem('userId', val)
        },
        setAuth(state, val) {
            let auth = JSON.stringify(val)
            state.auth = auth
            sessionStorage.setItem('auth', auth)
        },
        setOssToken(state, val) {
            state.ossToken = val
            localStorage.setItem('ossToken', JSON.stringify(val))
        },
    },
    actions: {},
    modules: {}
})