<template>
    <el-date-picker type="date" v-model="innerDate" @change="updateOuter"
                    @keydown.enter="updateOuter"
                    :shortcuts="dateShortcuts"></el-date-picker>
</template>

<script setup>
import {ref, reactive, onMounted, watch} from 'vue'
import {Plus, Search, Refresh, Edit, Delete, Check} from '@element-plus/icons-vue';
import {dateShortcuts} from "@/util/constant";
import {ElMessage} from 'element-plus'
import moment from 'moment'
import {debounce, throttle} from "@/util/common";

const props = defineProps({
    date: String | Date,//日期
})
const emits = defineEmits(['update:date'])
const innerDate = ref('')
watch(() => props.date, (n, o) => {
    innerDate.value = n
})
onMounted(() => {
    if (props.date) innerDate.value = props.date
})
const updateOuter = throttle((e) => {
    if (typeof (innerDate.value) === 'object') {
        innerDate.value = moment(innerDate.value).format('yyyy-MM-DD')
    } else if (e.target && e.target.value) {
        let targetValue = e.target.value
        if (targetValue.length === 8) {
            let year = targetValue.slice(0, 4)
            let month = targetValue.slice(4, 6)
            let day = targetValue.slice(6)
            innerDate.value = year + '-' + month + '-' + day
            e.target.value = innerDate.value
        } else if (targetValue.length === 6) {
            let newVal = '20' + targetValue
            let year = newVal.slice(0, 4)
            let month = newVal.slice(4, 6)
            let day = newVal.slice(6)
            innerDate.value = year + '-' + month + '-' + day
            e.target.value = innerDate.value
        } else {
            ElMessage.error('错误的字符串')
            return
        }
    }
    emits('update:date', innerDate.value)
}, 200)

</script>