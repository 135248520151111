import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn,
    size: 'default'
})
app.use(store)
app.use(router)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

//全局方法  使用provide/inject
// 跳转路由
app.provide('toPage', (uri, checkResult = true) => {
    if (checkResult) {
        router.push({
            name: uri
        })
    }
})
//复制对象字段
app.provide('setFields', (targetObj, val) => {
    for (let field in targetObj) {
        if (val[field] !== undefined) targetObj[field] = val[field]
    }
})
//清空对象字段
app.provide('initFields', (targetObj) => {
    for (let field in targetObj) {
        if (field === 'id' || field.indexOf('Id') >= 0 || field === 'points') {
            targetObj[field] = 0
        } else {
            targetObj[field] = ''
        }
    }
})
//自定义全局指令v-only-number 让输入框只能输入数字
import onlyNumber from './directive/onlyNumberDirective'

app.directive(onlyNumber.name, onlyNumber.directive)
import money from './directive/moneyDirective'

app.directive(money.name, money.directive)
import checkAuth from './directive/checkAuth'

app.directive(checkAuth.name, checkAuth.directive)
import fileSize from './directive/fileSizeDirective'

app.directive(fileSize.name, fileSize.directive)
import datetime from './directive/datetimeDirectives'

app.directive(datetime.name, datetime.directive)
import enumText from './directive/enumText'

app.directive(enumText.name, enumText.directive)
import bankNumber from './directive/bankNumber'

app.directive(bankNumber.name, bankNumber.directive)
import focus from './directive/focus'

app.directive(focus.name, focus.directive)
import onlyInt from './directive/onlyIntDirective'

app.directive(onlyInt.name, onlyInt.directive)
import dateRangePicker from "./components/dateRangePicker";
import datePicker from "./components/datePicker";
import cityInput from "./components/cityInput";
import picUpload from "./components/picUpload";

app.component('date-range-picker', dateRangePicker)
app.component('date-picker', datePicker)
app.component('city-input', cityInput)
app.component('pic-upload', picUpload)

app.mount('#app')

// 通用样式表
import './assets/main.scss'