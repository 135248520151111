export const getEnumList = enumItem => {
    let enumArr = []
    for (let item in enumItem) {
        enumArr.push({
            id: enumItem[item],
            name: item
        })
    }
    return enumArr
}
export const getEnumText = (id, enums) => {
    for (let item in enums) {
        if (enums[item] == id) return item
    }
    return ''
}
// 项目状态
export const adminProjectStateEnum = {
    已创建: 1,
    进行中: 2,
    暂停中: 3,
    测试中: 4,
    已结束: 5
}
// 任务状态
export const adminTaskStateEnum = {
    已创建: 1,
    进行中: 2,
    暂停中: 3,
    测试中: 4,
    已结束: 5
}
// 模块类型
export const moduleTypeEnum = {
    模块: 0,
    页面: 1,
    功能: 2,
}
// 功能版本
export const systemProductEnum = {
    基础版: 1,
    专业版: 2,
    仓库版: 3,
    // 企业版: 4,
}
// 文章类型
export const articleTypeEnum = {
    问答: 6,
    医疗政策: 1,
    求职技巧: 2,
    义齿加工: 3,
    会议展会: 4,
    媒体曝光: 5,
    口腔知识: 7,
}
export const fileUseEnum = {
    用户头像: 1,
    用户证书: 2,
    单位Logo: 3,
    单位照片: 4,
    营业执照: 5,
    文章封面: 6,
    文章图片: 7,
    帮助: 8,
}
// 文件夹前缀
export const folderPrefix = {
    用户头像: 'userAvatar',
    用户证书: 'userLicense',
    单位Logo: 'companyLogo',
    单位照片: 'companyInfo',
    营业执照: 'businessLicense',
    文章封面: 'articleCover',
    文章图片: 'articlePic',
    帮助: 'help'
}
// 文章标签
export const articleTagEnum = [
    '固定', '活动', '种植', '正畸', '全口', '基台', 'CAD/CAM', '模型', '蜡型', '铸造', '车金', '上瓷', 'OP', '铸瓷', '车瓷',
    '排牙', '胶托', '口扫', '数字化', '消毒', '排版', '切削', '打磨', '马龙桥', '外返', '内返', '过程质检', '出厂质检', '口腔展会',
    '临床', '销售', '医疗器械', '行业趋势', '医技沟通', '政策法规'
]
// 企业类型
export const companyTypeEnum = {
    义齿工厂: 1,
    医疗器械公司: 2,
    软件公司: 3
}
// 休息类型
export const restTypeEnum = {
    周休: 1,
    月休: 2
}

// 加班情况
export const overtimeEnum = {
    不加班: 1,
    偶尔加班: 2,
    弹性工作: 3
}

// 职位类别
export const jobTypeEnum = {
    质检: 1,
    采购: 2,
    收发部: 3,
    文员: 4,
    '实习/学徒': 5,
    业务员: 6,
    '代理/电销': 7,
    '石膏部/模型设计': 8,
    '蜡冠部/蜡冠设计': 9,
    车金部: 10,
    上瓷部: 11,
    车瓷部: 12,
    '全瓷/精密附件/3D打印': 13,
    '胶托部/正畸': 14,
    '钢托部/钢托设计': 15,
    钢牙部: 16,
    隐义部: 17,
    'CAD/CAM': 18,
    种植部: 19,
    临床支持: 20,
    美学部: 21,
    总监: 22,
    翻译: 23,
    '经理/厂长': 24,
    财务: 25,
}
// 审核状态
export const auditStateEnum = {
    不通过: -1,
    待审核: 0,
    通过: 1,
}

// 单位性质
export const companyNatureEnum = {
    民营: 1,
    中外合资: 2,
    外商独资: 3,
    国企: 4,
    上司公司: 5,
}