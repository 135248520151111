import moment from 'moment'

const pageSizeList = [10, 20, 30, 40]
const defaultPageSize = 10
export {
    pageSizeList,
    defaultPageSize,
}
// 富文本工具栏
export const editorOption = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
            ['blockquote', 'code-block'], //引用，代码块
            [{header: 1}, {header: 2}], // 标题，键值对的形式；1、2表示字体大小
            [{list: 'ordered'}, {list: 'bullet'}], //列表
            [{script: 'sub'}, {script: 'super'}], // 上下标
            [{indent: '-1'}, {indent: '+1'}], // 缩进
            [{direction: 'rtl'}], // 文本方向
            [{size: ['small', false, 'large', 'huge']}], // 字体大小
            [{header: [1, 2, 3, 4, 5, 6, false]}], //几级标题
            [{color: []}, {background: []}], // 字体颜色，字体背景颜色
            [{font: []}], //字体
            [{align: []}], //对齐方式
            // ['clean'], //清除字体样式
            ['image'], //上传图片、上传视频 ['image', 'video']
        ]
    },
    placeholder: '请输入内容...'
}
// 富文本工具栏-无图片
export const editorSimpleOption = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
            // ['blockquote', 'code-block'], //引用，代码块
            [{header: 1}, {header: 2}], // 标题，键值对的形式；1、2表示字体大小
            [{list: 'ordered'}, {list: 'bullet'}], //列表
            // [{script: 'sub'}, {script: 'super'}], // 上下标
            [{indent: '-1'}, {indent: '+1'}], // 缩进
            [{direction: 'rtl'}], // 文本方向
            // [{size: ['small', false, 'large', 'huge']}], // 字体大小
            [{header: [1, 2, 3, 4, 5, 6, false]}], //几级标题
            [{color: []}, {background: []}], // 字体颜色，字体背景颜色
            [{font: []}], //字体
            [{align: []}], //对齐方式
            // ['clean'], //清除字体样式
            // ['image'], //上传图片、上传视频 ['image', 'video']
        ]
    },
    placeholder: '请输入内容...'
}
// 日期快捷选择
export const dateShortcuts = [
    {
        text: '今天',
        value: new Date(),
    },
    {
        text: '明天',
        value: new Date(moment().add(1, 'days').format('yyyy-MM-DD HH:mm:ss')),
    },
    {
        text: '后天',
        value: new Date(moment().add(2, 'days').format('yyyy-MM-DD HH:mm:ss')),
    },
    {
        text: '3天后',
        value: new Date(moment().add(3, 'days').format('yyyy-MM-DD HH:mm:ss')),
    },
    {
        text: '7天后',
        value: new Date(moment().add(7, 'days').format('yyyy-MM-DD HH:mm:ss')),
    },
]
/**
 * 日期区间快捷选择
 */
export const rangeShortcuts = [
    {
        text: '今天',
        value: () => {
            const start = new Date(moment().format('yyyy-MM-DD 00:00:00'))
            const end = new Date(moment().format('yyyy-MM-DD 23:59:59'))
            return [start, end]
        },
    },
    {
        text: '最近7天',
        value: () => {
            const end = new Date(moment().format('yyyy-MM-DD 23:59:59'))
            const start = new Date(moment().add(-7, 'days').format('yyyy-MM-DD 00:00:00'))
            return [start, end]
        },
    },
    {
        text: '最近30天',
        value: () => {
            const end = new Date(moment().format('yyyy-MM-DD 23:59:59'))
            const start = new Date(moment().add(-30, 'days').format('yyyy-MM-DD 00:00:00'))
            return [start, end]
        },
    },
]