<template>
    <div>
        <el-upload action="#"
                   class="avatar-uploader" accept="image/*"
                   :before-upload="beforeUpload"
                   :auto-upload="true"
                   :on-success="successPicUpload"
                   :http-request="uploadFile"
                   :show-file-list="false">
            <img v-if="!!picUrl"
                 alt="图片"
                 :src="picUrl"
                 class="avatar"/>
            <el-icon v-else class="avatar-uploader-icon">
                <Plus/>
            </el-icon>
        </el-upload>
        <div>
            <el-button v-if="!!innerFileId" type="danger" link @click="removePic">
                删除
            </el-button>
            <el-button v-if="!!innerFileId" type="success" link
                       @click="previewPic">预览
            </el-button>
        </div>
        <el-dialog v-model="picVisible">
            <el-image :src="picUrl">
            </el-image>
        </el-dialog>
    </div>
</template>
<!--使用示例-->
<!--<pic-upload v-if="picVisible" :file-use="fileUseEnum.用户头像" :folder-prefix="folderPrefix.用户头像"-->
<!--            v-model:file-id="productFileId" v-model:file-token="productFileToken"-->
<!--            ></pic-upload>-->
<script setup>
import {ref, reactive, onMounted, onActivated, watch} from 'vue'
import {Plus} from '@element-plus/icons-vue';
import {fileUseEnum} from "@/util/enum.js";
import {beforePicUpload, beforeAvatarUpload, useGetFileUrl, useUploadFile} from "@/use/system/useFile.js";

const {getFileUrl} = useGetFileUrl()
const {
    uploadParam,
    uploadFileResult,
    uploadFileList,
    uploadFile,
} = useUploadFile()

const props = defineProps({
    fileId: Number,
    fileToken: String,
    folderPrefix: String,
    fileUse: Number,
})
const emits = defineEmits(['finishUpload', 'update:fileId', 'update:fileToken'])
const innerFileId = ref()
const innerFileToken = ref()
const picUrl = ref()//图片url
watch(() => props.fileId, async (n, o) => {
    innerFileId.value = n
})
watch(() => props.fileToken, async (n, o) => {
    if (n) {
        if (innerFileToken.value !== n) {
            picUrl.value = await getFileUrl(n)
        }
    } else {
        picUrl.value = ''
    }
    innerFileToken.value = n
})
watch(() => props.folderPrefix, (n, o) => {
    uploadParam.folderPrefix = n
})
watch(() => props.fileUse, (n, o) => {
    uploadParam.fileUse = n
})
let beforeUpload = beforePicUpload
onMounted(async () => {
    if (props.fileUse === fileUseEnum.用户头像 || props.fileUse === fileUseEnum.单位Logo || props.fileUse === fileUseEnum.文章封面) {
        beforeUpload = beforeAvatarUpload
    }
    uploadParam.folderPrefix = props.folderPrefix
    uploadParam.fileUse = props.fileUse
    innerFileId.value = props.fileId
    innerFileToken.value = props.fileToken
    if (props.fileToken) {
        picUrl.value = await getFileUrl(props.fileToken)
    }
})

const successPicUpload = param => {
    innerFileId.value = uploadFileResult.picId
    innerFileToken.value = uploadFileResult.picToken
    emits('update:fileId', innerFileId.value)
    emits('update:fileToken', innerFileToken.value)
    setTimeout(async () => {
        picUrl.value = await getFileUrl(uploadFileResult.picToken)
    }, 1000)
    emits('finishUpload', {
        fileId: innerFileId.value,
        fileToken: innerFileToken.value
    })
}
const removePic = param => {
    uploadFileList.value = []
    uploadFileResult.picId = ''
    uploadFileResult.picToken = ''
    innerFileId.value = ''
    innerFileToken.value = ''
    picUrl.value = ''
    emits('finishUpload', null)
    emits('update:fileId', '')
    emits('update:fileToken', '')
}
const picVisible = ref(false)
const previewPic = param => {
    picVisible.value = true
}
</script>