<template>
    <el-space :size="0">
        <el-select v-model="province" filterable placeholder="省"
                   @change="changeProvince" class="w-sm">
            <el-option v-for="item in provinceList" :key="item.code"
                       :value="item.name">
            </el-option>
        </el-select>
        <el-select v-model="city" filterable placeholder="市"
                   @change="changeCity" class="w-sm">
            <el-option v-for="item in cityList" :key="item.code" :value="item.name">
            </el-option>
        </el-select>
        <el-select v-model="area" filterable placeholder="区"
                   class="w-sm">
            <el-option v-for="item in areaList" :key="item.code" :value="item.name">
            </el-option>
        </el-select>
    </el-space>
</template>

<script setup>
import {ref, reactive, onMounted, watch} from 'vue'
import {useAreaDetail} from "@/util/area";

const province = defineModel("province", {type: String})
const city = defineModel("city", {type: String})
const area = defineModel("area", {type: String})

const {
    provinceList,
    cityList,
    areaList,
    changeProvince,
    changeCity,
    initSelect
} = useAreaDetail(province, city, area)
onMounted(() => {
    initSelect()
})
</script>

<style scoped>

</style>