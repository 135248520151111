<template>
    <router-view v-if="routeAlive"/>
</template>

<style>
html, body {
    height: 100%;
}

body {
    display: flex;
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
    display: flex;
    flex-grow: 1;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

.text-primary {
    color: #409EFF !important;
}

.text-success {
    color: #67C23A !important;
}

.text-warning {
    color: #E6A23C !important;
}

.text-danger {
    color: #F56C6C !important;
}

.text-info {
    color: #909399 !important;
}

.btn-icon {
    width: 1em;
    height: 1em;
    margin-right: 3px
}

.input-small {
    width: 100px !important;;
}

.w-150 {
    width: 150px !important;;
}

.input-medium {
    width: 200px !important;
}

.input-large {
    width: 300px !important;;
}

.input-xl {
    width: 500px !important;;
}

.w-full {
    width: 100%;
}
</style>
<script setup>
import {provide, ref, nextTick} from 'vue'

const routeAlive = ref(true)
provide('reload', () => {
    routeAlive.value = false
    nextTick(() => {
        routeAlive.value = true
    })
})

import * as echarts from 'echarts'

provide('echarts', echarts)
</script>