import moment from 'moment'
// 本文件用来存放各种格式化数据的方法

// 用来格式化el-table中的日期格式
function datetimeFormat(row, col, val, index) {
    if (val) {
        return moment(val).format('yyyy-MM-DD HH:mm:ss')
    }
    return val
}

// 用来格式化el-table中的日期格式
function dateFormat(row, col, val, index) {
    if (val) {
        return moment(val).format('yyyy-MM-DD')
    }
    return val
}
function txtDateFormat(val){
    if (val) {
        return moment(val).format('yyyy-MM-DD')
    }
    return val
}
// 格式化表格的枚举值
// 使用方法示例    :formatter="(r,c,v)=>enumTypeFormatter(v,trainType)"
function enumTypeFormatter(val, enumList) {
    let result = enumList.find(x => x.id === val)
    if (result) {
        return result.name
    } else {
        return ''
    }
}

export {
    txtDateFormat,
    datetimeFormat,
    dateFormat,
    enumTypeFormatter
}